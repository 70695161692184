<template>
  <div class="d-flex mb-2">
    <div class="flex-fill">
      <div class="input-group">
        <search />
      </div>
    </div>
  </div>
  <div class="row g-2">
    <div class="col-md-3">
      <div class="card">
        <div class="list-group list-group-flush">
          <div
            v-for="(tab, idx) in navTabs"
            :id="`nav-${tab}-tab`"
            :key="idx"
            :class="`list-group-item list-group-item-action
              d-flex
              gap-2
              align-items-center ${chartId == tab ? 'active' : ''}`"
            data-bs-toggle="tab"
            :data-bs-target="`#nav-${tab}`"
            role="tab"
            :aria-controls="`nav-${tab}`"
            :aria-selected="!idx ? 'true' : 'false'"
            @click="onTabChange(tab)"
          >
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <i :class="`bi bi-pie-chart`"></i>
              </div>
              <div class="flex-grow-1 ms-3">
                {{ $t(`analytics_charts.${tab}`) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-9">
      <div class="mb-2">
        <blockchain-chart
          :front-page="false"
          :graph="currGraph"
          chart-height="327px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import Search from "@/components/Search.vue";
import BlockchainChart from "@/components/charts/BlockchainChart.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "NetworkDetailsCharts",
  components: { Search, BlockchainChart },
  setup() {
    const route = useRoute();
    const currGraph = ref("gf_active_addresses");
    const navTabs = [
      "total_accounts",
      "total_assets",
      "active_addresses_used",
      "inactive_addresses",
      "top_100_active_addresses",
      "successful_txns_per_day",
      "failed_txns_per_day",
      "txns_per_second",
      "output_value_per_day",
      "estimated_transaction_value",
    ];
    const onTabChange = (tab) => {
      switch (tab) {
        case "active_addresses_used":
          currGraph.value = "gf_active_addresses";
          break;
        case "inactive_addresses":
          currGraph.value = "gf_inactive_addresses";
          break;
        case "top_100_active_addresses":
          currGraph.value = "gf_top_100_active_addresses";
          break;
        case "successful_txns_per_day":
          currGraph.value = "gf_successful_txns_per_day";
          break;
        case "failed_txns_per_day":
          currGraph.value = "gf_failed_txns_per_day";
          break;
        case "txns_per_second":
          currGraph.value = "gf_txns_per_second";
          break;
        case "output_value_per_day":
          currGraph.value = "gf_output_value_per_day";
          break;
        case "estimated_transaction_value":
          currGraph.value = "gf_estimated_txn_value";
          break;
        case "total_accounts":
          currGraph.value = "total_accounts";
          break;
        case "total_assets":
          currGraph.value = "total_assets";
          break;
      }
    };
    const blockchainChartInfo =
      window.screen.width <= 768
        ? {
            margins: { left: "11%", top: "6%", width: "92%", height: "83%" },
            class: "d-md-none",
          }
        : {
            margins: { left: "4%", top: "6%", width: "92%", height: "83%" },
            class: "d-none d-md-block",
          };


    let chartId = 'active_addresses_used';
    if (route.params.chartId) {
      chartId = route.params.chartId;
      onTabChange(chartId);
    }

    return {
      navTabs,
      onTabChange,
      currGraph,
      chartId,
      blockchainChartInfo,
    };
  },
});
</script>

<style>
</style>
