/* eslint-disable vue/require-default-prop */
<template>
  <div class="card mb-2" :style="{ height: chartHeight }">
    <div
      class="
        card-header
        bg-body
        border-bottom-0
        text-uppercase
        d-flex
        justify-content-between">
      <span class="badge text-muted ps-0 text-uppercase">{{$t(`charts.${graph.replace("gf_", "")}`)}}</span>
    </div>
    <div
      ref="chartContainer"
      class="card-body p-0"
      style="margin-right: 15px">
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  watch,
  onMounted,
  onUnmounted,
} from "vue";
import { useStore } from "vuex";
import { createChart } from "lightweight-charts";
import {
  createTooltip,
  chartTooltip,
  chartOptions,
  seriesOptions,
  avgLineValue,
  valueFormatter,
} from "@/utils/chart.js";

export default defineComponent({
  name: "BlockchainChart",
  props: {
    frontPage: Boolean,
    graph: String,
    chartHeight: String,
  },
  setup(props) {
    const store = useStore();
    const aggregation = "d";
    const chartContainer = ref();
    const toolTip = ref(createTooltip());

    let series;
    let chart;

    onMounted(() => {
      store.dispatch("charts/fetchBlockchainData", {
        graph: props.graph,
        aggregation,
      });
      chart = createChart(chartContainer.value, chartOptions);
      chart.applyOptions({
        localization: {
          priceFormatter: valueFormatter(props.graph),
        },
      });
      addSeriesAndData(blockchainData.value);
      chartContainer.value.appendChild(toolTip.value);
      chart.subscribeCrosshairMove(displayTooltip);

      chart.timeScale().fitContent();
    });

    const blockchainData = computed(() =>
      store.getters["charts/getBlockchainData"][props.graph]?.map((item) => {
        return {
          time: Math.floor(Date.parse(item.date) / 1000),
          value: parseFloat(item[props.graph]),
        };
      })
    );
    const avgValue = computed(
      () =>
        blockchainData.value.reduce((total, next) => total + next.value, 0) /
        blockchainData.value.length
    );
    const resizeHandler = () => {
      if (!chart || !chartContainer.value) return;
      const dimensions = chartContainer.value.getBoundingClientRect();
      chart.resize(dimensions.width, dimensions.height);
    };
    const displayTooltip = (param) =>
      chartTooltip(
        param,
        chartContainer.value,
        series,
        props.graph,
        toolTip.value,
        "charts"
      );
    // Creates the chart series and sets the data.
    const addSeriesAndData = (blockchainData) => {
      series = chart["addAreaSeries"](seriesOptions);
      if(!blockchainData) {
         return false;
      }
      series.setData(blockchainData);
      series.createPriceLine(avgLineValue(avgValue.value));
      chart.timeScale().fitContent();
      window.addEventListener("resize", resizeHandler);
    };
    watch(
      () => props.graph,
      (graph) => {
        store.dispatch("charts/fetchBlockchainData", {
          graph: graph,
          aggregation,
        });
      }
    );
    watch(
      () => blockchainData.value,
      (blockchainData) => {
        if (series && chart) {
          chart.removeSeries(series);
        }
        chart.applyOptions({
          localization: {
            priceFormatter: valueFormatter(props.graph),
          },
        });
        addSeriesAndData(blockchainData);
      }
    );

    onUnmounted(() => {
      chart.unsubscribeCrosshairMove(displayTooltip);
      if (chart) {
        chart.remove();
        chart = null;
      }
      if (series) {
        series = null;
      }
    });

    return { store, chartContainer };
  },
});
</script>

    
